import React from "react";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const Schedule = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <div
      style={{
        height: "90vh",
      }}
    >
      <Viewer
        fileUrl="/assets/convention_schedule.pdf"
        plugins={[defaultLayoutPluginInstance]}
        defaultScale={SpecialZoomLevel.PageFit}
      />
    </div>
  );
};

export default Schedule;
