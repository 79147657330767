import React from "react";
import { Button, Typography } from "@mui/material";

const MenuButton = (props) => {
  const { title, Icon, onClick, href } = props;
//   const Icon = props.icon;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Button
        variant="contained"
        style={{
          margin: "2.5vh 2vw 0.5vh 2vw",
          height: "70px",
          width: "70px",
          borderRadius: "30px",
        }}
        onClick={onClick ? onClick : null}
        href={href ? href : null}
        target={href ? "_blank" : "_self"}
      >
        <Icon fontSize="large" />
      </Button>
      <Typography
        variant="h6"
        align="center"
        style={{ marginLeft: "5px", marginRight: "5px" }}
      >
        {title}
      </Typography>
    </div>
  );
};

export default MenuButton;
