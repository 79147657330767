import { Button, MenuItem, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

const adminKey = "admin";

const Admin = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [inputKey, setInputKey] = useState("");
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationBody, setNotificationBody] = useState("");
  const [subscriptionList, setSubscriptionList] = useState("");

  const handleKeyInputChange = (e) => {
    setInputKey(e.target.value);
  };

  const handleKeySubmitClick = () => {
    if (inputKey === adminKey) setIsAdmin(true);
  };

  const handleSubscriptionListChange = (e) => {
    setSubscriptionList(e.target.value);
  };

  const handleNotificationTitleChange = (e) => {
    setNotificationTitle(e.target.value);
  };

  const handleNotificationBodyChange = (e) => {
    setNotificationBody(e.target.value);
  };

  const handleSendNotificationClick = async () => {
    const url =
      "https://4zj1xd4h83.execute-api.us-east-2.amazonaws.com/prod/sendPushNotification";

    const response = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title: notificationTitle,
        body: notificationBody,
      }),
    });
    console.log(response.json());
  };

  return isAdmin ? (
    <div
      style={{
        height: "90vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h5" style={{ marginBottom: "2vh" }}>
        Send Notification
      </Typography>
      <TextField
        select
        label="Subscription List"
        value={subscriptionList}
        onChange={handleSubscriptionListChange}
        style={{ margin: "2vh 0 2vh 0", width: "275px" }}
      >
        <MenuItem value="General">General</MenuItem>
        <MenuItem value="ADYA">ADYA</MenuItem>
      </TextField>
      <TextField
        label="Notification Title"
        value={notificationTitle}
        onChange={handleNotificationTitleChange}
        style={{ margin: "2vh 0 2vh 0", width: "275px" }}
      />
      <TextField
        multiline
        label="Notification Body"
        value={notificationBody}
        onChange={handleNotificationBodyChange}
        style={{ margin: "2vh 0 2vh 0", width: "275px" }}
      />
      <Button
        variant="contained"
        onClick={handleSendNotificationClick}
        style={{ margin: "2vh 0 2vh 0", width: "100px" }}
      >
        Send
      </Button>
    </div>
  ) : (
    <div
      style={{
        height: "90vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div>
        <Typography variant="h5" style={{ marginBottom: "2vh" }}>
          Admin Portal
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <TextField
          label="Input Key"
          variant="outlined"
          onChange={handleKeyInputChange}
        />
        <Button
          variant="contained"
          style={{ marginLeft: "1vw", width: "100px" }}
          onClick={handleKeySubmitClick}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default Admin;
