import React, { useState } from "react";
import { Button, IconButton, Typography } from "@mui/material";
import MenuButton from "./MenuButton";
import CreditsModal from "./CreditsModal";
import FeedbackModal from "./FeedbackModal";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
// import BusinessIcon from "@mui/icons-material/Business";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import MapIcon from "@mui/icons-material/Map";
import ForumIcon from "@mui/icons-material/Forum";
import InstagramIcon from "@mui/icons-material/Instagram";
// import SellIcon from "@mui/icons-material/Sell";
// import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PersonIcon from "@mui/icons-material/Person";
// import PollIcon from "@mui/icons-material/Poll";
import { useNavigate } from "react-router-dom";
// import { urlBase64ToUint8Array } from "../helpers/helpers";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "275px",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

// const sendSubscriptionToServer = async (subscription) => {
//   const SERVER_URL =
//     "https://4zj1xd4h83.execute-api.us-east-2.amazonaws.com/prod/pushSubscriptions";
//   const response = await fetch(SERVER_URL, {
//     method: "post",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({
//       subscriptionToken: subscription.endpoint,
//       subscription: subscription,
//     }),
//   });
//   return response.json();
// };

const Home = (props) => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isCreditsModalOpen, setIsCreditsModalOpen] = useState(false);

  // const { detectedOS } = props;

  const navigate = useNavigate();

  const handleScheduleClick = () => {
    navigate("/schedule");
  };

  const handleMapClick = () => {
    navigate("/map");
  };

  //   const handleDonorsClick = () => {
  //     navigate("/donors");
  //   };

  //   const handleSupportersClick = () => {
  //     navigate("/supporters");
  //   };

  //   const handleAuctionItemsClick = () => {
  //     navigate("/auction");
  //   };

  const handleConnectWithUsClick = () => {
    navigate("/connect");
  };

  const handleFeedbackClick = () => {
    if (Date.now() < Date.parse("2023-11-25T19:00:00"))
      setIsFeedbackModalOpen(true);
  };

  //   const handleDruzeBeCountedClick = () => {
  //     navigate("/druze_be_counted");
  //   };

  //   const handleGeneralSubscriptionClick = () => {
  //     const publicKey =
  //       "BC04MteHv92wkT6Jd7Aiea_VPuPr0eolcn3ICnPLWYilaBE7fdhAYZod3vI-oh1ZYC5J2L1Md6py5tPB6jT5HqE";

  //     Notification.requestPermission()
  //       .then((permissionsResponse) => {
  //         if (permissionsResponse === "granted") {
  //           return navigator.serviceWorker.getRegistration();
  //         }
  //         throw new Error("Permission not granted");
  //       })
  //       .then((registration) => {
  //         const subscriptionOptions = {
  //           userVisibleOnly: true,
  //           applicationServerKey: urlBase64ToUint8Array(publicKey),
  //         };

  //         if (!("PushManager" in window)) {
  //           throw new Error("that aint it bro");
  //         }

  //         return registration.pushManager.subscribe(subscriptionOptions);
  //       })
  //       .then((subscription) => {
  //         console.log(subscription);
  //         return sendSubscriptionToServer(subscription);
  //       })
  //       .then((response) => {
  //         console.log("hi");
  //         console.log(response);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };

  //   const handleADYASubscriptionClick = () => {};

  const handleCreditsClick = () => {
    setIsCreditsModalOpen(true);
  };

  return (
    <>
      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        onClose={() => setIsFeedbackModalOpen(false)}
        style={modalStyle}
      />
      <CreditsModal
        isOpen={isCreditsModalOpen}
        onClose={() => setIsCreditsModalOpen(false)}
        style={modalStyle}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          height: "85vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h4"
            align="center"
            style={{ marginBottom: "4vh" }}
          >
            Welcome to San Antonio
          </Typography>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "33%" }}>
              <MenuButton
                title="Schedule"
                Icon={CalendarMonthIcon}
                onClick={handleScheduleClick}
              />
            </div>
            <div style={{ width: "33%" }}>
              <MenuButton
                title="Hotel Map"
                Icon={MapIcon}
                onClick={handleMapClick}
              />
            </div>
            <div style={{ width: "33%" }}>
              <MenuButton
                title="Donate"
                Icon={AttachMoneyIcon}
                href="https://www.druze.com/calendar/donate"
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "40%" }}>
              <MenuButton
                title="Connect With Us"
                Icon={InstagramIcon}
                onClick={handleConnectWithUsClick}
              />
            </div>
            <div style={{ width: "40%" }}>
              <MenuButton
                title="Feedback"
                Icon={ForumIcon}
                onClick={handleFeedbackClick}
                href={
                  Date.now() >= Date.parse("2023-11-25T19:00:00")
                    ? "https://forms.gle/atbqMJvxvXWDd3PR7"
                    : ""
                }
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">Event Notifications</Typography>
          <Typography
            style={{
              textAlign: "center",
              paddingLeft: "10px",
              paddingRight: "10px",
              marginBottom: "4vh",
            }}
            fontSize={14}
          >
            Click to join WhatsApp notification groups.
          </Typography>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              variant="contained"
              style={{
                margin: "1.5vh 2vw 1.5vh 2vw",
                width: "150px",
                backgroundColor: "#075e54",
              }}
              href="https://chat.whatsapp.com/FabVbYACvWOHOWikXzlXZm"
              target="_blank"
            >
              General{" "}
              <WhatsAppIcon fontSize="small" style={{ marginLeft: "7px" }} />
            </Button>
            <Button
              variant="contained"
              style={{
                margin: "1.5vh 2vw 1.5vh 2vw",
                width: "150px",
                backgroundColor: "#075e54",
              }}
              href="https://chat.whatsapp.com/K9zuj1iR6lN1ltuh8jpMfO"
              target="_blank"
            >
              ADYA{" "}
              <WhatsAppIcon fontSize="small" style={{ marginLeft: "7px" }} />
            </Button>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "5vh",
        }}
      >
        <IconButton onClick={handleCreditsClick}>
          <PersonIcon />
        </IconButton>
      </div>
    </>
  );
};

export default Home;
