import React from "react";
import { Typography } from "@mui/material";
import druzeBeCounted from "../assets/druzeBeCounted.png";

const DruzeBeCounted = () => {
  return (
    <div
      style={{
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h6" textAlign={"center"}>
        Click image to go to the survey
      </Typography>
      <a
        href="https://questionpro.com/t/AWxLWZvq1l"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={druzeBeCounted}
          alt="druzeBeCounted"
          style={{ maxWidth: "100vw", maxHeight: "85vh" }}
        />
      </a>
    </div>
  );
};

export default DruzeBeCounted;
