import React from "react";
import { Typography } from "@mui/material";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const Map = () => {
  return (
    <div
      style={{
        height: "90vh",
      }}
    >
      <Typography variant="h6" textAlign={"center"}>
        Pinch to Zoom In/Out
      </Typography>
      <Viewer
        fileUrl="/assets/resort_map.pdf"
        defaultScale={SpecialZoomLevel.PageFit}
      />
    </div>
  );
};

export default Map;
