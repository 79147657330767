import React from "react";
import { Button, Toolbar, AppBar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import druze_logo from "../assets/druze_star.svg";
import app_logo from "../assets/app_logo_2023.jpg";

const Header = () => {
  const navigate = useNavigate();

  return (
    <AppBar position="sticky" style={{ height: "10vh" }}>
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <Button onClick={() => navigate("/")}>
          <img
            src={druze_logo}
            alt="druze_logo"
            style={{ height: "7.5vh", margin: "1.25vh 0 1.25vh 0" }}
          />
        </Button>
        <img src={app_logo} alt="app_logo" style={{ height: "7.5vh", margin: "1.25vh 0 1.25vh 0" }}/>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
