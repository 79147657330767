import React from "react";
import { Typography } from "@mui/material";

const Supporters = () => {
  return (
    <div>
      <div style={{ textAlign: "center", margin: "30px 0px 20px 0px" }}>
        <Typography variant="h4">Special Thanks To Our Donors</Typography>
      </div>
      <div
        style={{
          display: "flex",
		  flexDirection: "column",
          alignItems: "center",
        }}
      >
		<Typography variant="h5">Samer and Rihab Suki</Typography>
		<Typography variant="h5">Ghassan and Manal Saab</Typography>
		<Typography variant="h5">Husam and Samera Jawhari</Typography>
		<Typography variant="h5">Sevenseas Investment Group</Typography>
		<Typography variant="h5">Samer and Ruba Abdulbaki</Typography>
		<Typography variant="h5">Zouhair and Nina Fares</Typography>
		<Typography variant="h5">Mark and Soha Boukzam</Typography>
		<Typography variant="h5">Khaled and Nawal Jawhari</Typography>
		<Typography variant="h5">Haitham and Amal Faraj</Typography>
		<Typography variant="h5">Bassam and Rayya Abifaraj</Typography>
		<Typography variant="h5">American Druze Foundation</Typography>
		<Typography variant="h5">Wajdi Abou-Chakra</Typography>
		<Typography variant="h5">Rashid Bou Diab</Typography>
		<Typography variant="h5">Salah and Nida Alandary</Typography>
		<Typography variant="h5">Sami Aboumatar</Typography>
		<Typography variant="h5">Marwan Aridi</Typography>
		<Typography variant="h5">Issam and Hala Bou-Hamdan</Typography>
		<Typography variant="h5">Sarah Cosmetics</Typography>
		<Typography variant="h5">Hospitality Dallas Ladies</Typography>
		<Typography variant="h5">National's Women Auxiliary</Typography>
		<Typography variant="h5">Paris Fragrance</Typography>
		<Typography variant="h5">Golden State Jewelry</Typography>
		<Typography variant="h5">Marvesol</Typography>
      </div>
      <br />
      <br />
    </div>
  );
};

export default Supporters;
