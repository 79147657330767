import React from "react";
import { Backdrop, Box, Button, Fade, Modal, Typography } from "@mui/material";

const FeedbackModal = (props) => {
  const { isOpen, onClose, style } = props;

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">
              The feedback survey will open at 7:00 PM on Saturday, Nov 25.
            </Typography>
            <br />
            <Button
              variant="contained"
              style={{ margin: "1.5vh", width: "70px" }}
              onClick={onClose}
            >
              OK
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default FeedbackModal;
