import React, { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Worker } from "@react-pdf-viewer/core";
import Header from "./components/Header";
import Home from "./components/Home";
import Schedule from "./components/Schedule";
import Map from "./components/Map";
import Donors from "./components/Donors";
import Supporters from "./components/Supporters";
import Auction from "./components/Auction";
import ConnectWithUs from "./components/ConnectWithUs";
import DruzeBeCounted from "./components/DruzeBeCounted";
import Admin from "./components/Admin";
import FoodMenu from "./components/FoodMenu";
import "./App.css";

const theme = createTheme({
  palette: {
    primary: { main: "#23438e" },
    secondary: { main: "#d6d6d6" },
  },
  typography: {
    fontFamily: ["Titillium Web", "sans-serif"].join(","),
  },
});

function App() {
  const [detectedOS, setDetectedOS] = useState(null);

  useEffect(() => {
    detectOS();
  });

  const detectOS = () => {
    if (navigator.platform.includes("iPhone")) {
      setDetectedOS("iOS");
    }
  };

  console.log(process.env.NODE_ENV);

  return (
    <Worker workerUrl="/pdf.worker.min.js">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Home detectedOS={detectedOS} />} />
            <Route path="schedule" element={<Schedule />} />
            <Route path="map" element={<Map />} />
            <Route path="donors" element={<Donors />} />
            <Route path="supporters" element={<Supporters />} />
            <Route path="auction" element={<Auction />} />
            <Route path="connect" element={<ConnectWithUs />} />
            <Route path="druze_be_counted" element={<DruzeBeCounted />} />
            <Route path="admin" element={<Admin />} />
            <Route path="food_menu" element={<FoodMenu />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Worker>
  );
}

export default App;
