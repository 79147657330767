import React from "react";
import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";

const CreditsModal = (props) => {
  const { isOpen, onClose, style } = props;

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="overline">Created by</Typography>
            <Typography variant="h6">Hadi Gharizi</Typography>
            <br />
            <Typography variant="overline">Assets by</Typography>
			<Typography variant="h6">Amir Alabbas</Typography>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CreditsModal;
