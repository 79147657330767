import React from "react";
import { Button, Typography } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";

const ConnectWithUs = () => {
  return (
    <div
      style={{
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h5" style={{ marginBottom: "20px" }}>
        Connect with us on Instagram!
      </Typography>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Button
            variant="contained"
            style={{
              margin: "1.5vh 2vw 1.5vh 2vw",
              width: "180px",
            }}
            href="https://www.instagram.com/ads.dallas/"
            target="_blank"
          >
            ADS Dallas{" "}
            <InstagramIcon fontSize="small" style={{ marginLeft: "7px" }} />
          </Button> */}
          {/* <Button
            variant="contained"
            style={{
              margin: "1.5vh 2vw 1.5vh 2vw",
              width: "180px",
            }}
            href="https://www.instagram.com/adya.sanantonio/"
            target="_blank"
          >
            ADYA San Antonio{" "}
            <InstagramIcon fontSize="small" style={{ marginLeft: "7px" }} />
          </Button> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            style={{
              margin: "1.5vh 2vw 1.5vh 2vw",
              width: "180px",
            }}
            href="https://www.instagram.com/adya.sanantonio/"
            target="_blank"
          >
            ADYA San Antonio{" "}
            <InstagramIcon fontSize="small" style={{ marginLeft: "7px" }} />
          </Button>
          <Button
            variant="contained"
            style={{
              margin: "1.5vh 2vw 1.5vh 2vw",
              width: "180px",
            }}
            href="https://www.instagram.com/americandruzesociety/"
            target="_blank"
          >
            ADS National{" "}
            <InstagramIcon fontSize="small" style={{ marginLeft: "7px" }} />
          </Button>
          <Button
            variant="contained"
            style={{
              margin: "1.5vh 2vw 1.5vh 2vw",
              width: "180px",
            }}
            href="https://www.instagram.com/adya.national/"
            target="_blank"
          >
            ADYA National{" "}
            <InstagramIcon fontSize="small" style={{ marginLeft: "7px" }} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConnectWithUs;
